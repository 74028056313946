import React from "react";
import { Img, Heading, VStack } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";

const date = "28 de Octubre 2023";
// const names = "Jasmin & Luigui";
// const bio2 = ""

// Implement the UI for the LandingSection component according to the instructions.
// Use a combination of Avatar, Heading and VStack components.
const LandingSection = () => (
  <VStack
    style={{
      backgroundImage: "url(" + require("../images/jasminluigui.jpg") + ")",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    }}
    alignItems="center"
    minHeight="100vh"
    justifyContent="center"
    color="white"
  >
    <VStack spacing={0}>
      <VStack spacing={6}>
        <Img src="https://jasminluigui.com/namesLogo.png" maxWidth={"90%"}></Img>
      </VStack>
      <VStack spacing={40}>
        <Heading as="h4" size="md" noOfLines={1}>
          {date}
        </Heading>
      </VStack>
    </VStack>
  </VStack>
);

export default LandingSection;
