import { React, useMemo } from "react";
import { Link, Img, Heading } from "@chakra-ui/react";
import FullScreenSection from "./FullScreenSection";
import { GoogleMap, MarkerF } from "@react-google-maps/api";

const Map = () => {
  const containerStyle = {
    width: "400px",
    height: "400px",
  };

  const center = useMemo(
    () => ({
      lat: 20.28948934662392, // Latitude
      lng: -103.40981337776176, // Longitude
    }),
    []
  );

  return (
    <FullScreenSection
      backgroundColor="white"
      py={16}
      spacing={8}
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Heading as="h1" id="map">
        Mapa para llegar al lugar
      </Heading>
      <Img src="https://jasminluigui.com/chante.png" alt="Fachada Chante" />

      <Heading size="md" textAlign={"center"}>
        <Link href="https://maps.app.goo.gl/KhrLrPkmiVG3ZJLDA">
          C. Morelos 68, 45825 Chantepec, Jal., Mexico{" "}
        </Link>
      </Heading>

      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={16}>
        <MarkerF position={center} />
      </GoogleMap>
    </FullScreenSection>
  );
};

export default Map;
