import React from "react";
import FullScreenSection from "./FullScreenSection";
import { Box, Heading } from "@chakra-ui/react";
import Card from "./Card";

const details = [
  {
    title: "Hora de llegada ⏰",
    description:
      "Llegar a la 1:30 pm. La ceremonia se llevará a cabo a las 2:00 pm en punto a la orilla del lago.",
    getImageSrc: () => require("../images/photo1.avif"),
  },
  {
    title: "Lista de invitados ✅",
    description:
      "En la entrada se encontrará una lista de invitados con las mesas asignadas (considerar que personas que no se encuentren en la lista no tendrán asiento designado).",
    getImageSrc: () => require("../images/photo2.jpg"),
  },
  {
    title: "Código de vestimenta 👗",
    description: "Vestimenta formal para evento de día.",
    getImageSrc: () => require("../images/photo3.avif"),
  },
  {
    title: "Regalos 💵",
    description:
      "En lugar de recibir regalos para nuestra boda, damos la opción de recibir contribuciones financieras para nuestra Luna de Miel.",
    getImageSrc: () => require("../images/photo4.jpg"),
  },
];

const DetailsSection = () => {
  return (
    <FullScreenSection
      backgroundColor="#14532d"
      isDarkBackground
      p={8}
      alignItems="center"
      spacing={8}
      justifyContent="center"
    >
      <Heading as="h1" id="detalles"
        textAlign="center"
      >
        Detalles de nuestra boda
      </Heading>
      <Box
        display="grid"
        gridTemplateColumns="repeat(4,minmax(1,1fr))"
        gridGap={8}
      >
        {details.map((project) => (
          <Card
            key={project.title}
            title={project.title}
            description={project.description}
            imageSrc={project.getImageSrc()}
          />
        ))}
      </Box>
    </FullScreenSection>
  );
};

export default DetailsSection;
